.ant-picker-dropdown {
    z-index: 1500 !important;
}

.ant-picker-small:not(.HasError) {
    border: 1px solid #d9d9d9;
    padding: 6px 7px;
    border-radius: 5px !important;
    width: 100% !important;
    border-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: #d9d9d9 !important;

    border-left-style: solid !important;
    ;
    border-left-color: #d9d9d9 !important;
    border-right-style: solid !important;
    border-right-color: #d9d9d9 !important;
    border-bottom-color: #d9d9d9 !important;
}

.ant-picker-small.HasError {
    border: 1px solid #ED1C24;
    padding: 6px 7px;
    border-radius: 5px !important;
    width: 100% !important;
    border-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: #ED1C24 !important;

    border-left-style: solid !important;
    ;
    border-left-color: #ED1C24 !important;
    border-right-style: solid !important;
    border-right-color: #ED1C24 !important;
    border-bottom-color: #ED1C24 !important;
}

.ant-picker-input>input {
    font-size: 14px !important;
}

.dragging{
    cursor:grabbing;
  }
